import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ६ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ६ वा' num='06'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । श्री नृसिंह अवतार श्रेष्ठ ठरला । भक्त प्रल्हाद कारण ठरला । भक्ताच्या तळमळीला । भगवंत साकार झाला ॥ १ ॥
भगवंत विविध रूपात । दिसतो साकारात । विविध प्रसंगात । तत्पर धर्म रक्षणात ॥ २ ॥
असुरांचा होतो भार । धरणी होता बेजार । तू घेतोस अवतार । करतोस असुर संहार ॥ ३ ॥ 
हे नृसिंहा, भगवंता । असुरी वृत्ती संहारकर्ता । तार गणामहाराजास आता । नको नाव निशाण आता ॥ ४ ॥
शरणागतीत सारे मिळते । उद्धटपणात सारे जाते । विनय विनम्र भावे जगण्याते । ईश्वरीतत्व जागृत होते ॥ ५ ॥
अनुभवाच्या बळावर । भक्त येतो भानावर । जैसा पाटिल भास्कर । कळले जीवनातले सार ॥ ६ ॥
महाराजांचे भक्त अनेक । परी जवळचे मोजकेच । बंकट त्यातला एक । विनयी विनम्र सेवक ॥ ७ ॥
समर्थ बंकटाच्या घरी असता । पदोपदी झाला जपता । त्यांच्या मनाजोगे होता । आनंद वाटे बंकटा ॥ ८ ॥
मोठा धार्मिक वृत्तीचा । आधी समर्थांस द्यावयाचा । मगच स्वतः घ्यायचा । हाच नित्यक्रम त्याचा ॥ ९ ॥
वाटे समर्थच प्रपंच चालवतात । तेच सर्व सोय करतात । ते जे जे मला देतात । त्यातलेच ठेवतो नैवेद्यात ॥ १० ॥
आधी नैवेद्य महाराजांना । मग प्रसाद सर्वांना । कैसे विसरणार त्यांना ? । विसरण्यात विपरित प्रसंग नाना ॥ ११ ॥
नाना तर्‍हेचे भक्त नाना । बिकट प्रसंग महाराजांना । समर्थांची ईच्छा नसताना । करावे लागतसे नाना ॥ १२ ॥
बंकटे आग्रह केला । शेतावर नेण्याचा हट्ट केला । समर्थांस नेले शेताला । मक्याची कणसे खावयाला ॥ १३ ॥
ऐसे वाटले बंकटास । समर्थ चरण लागावे शेतास । भूमी पावन करण्यास । कृपा प्रसाद परिवारास ॥ १४ ॥
बंकट मुळचा पवित्र । संस्कारी मातापित्याचे छत्र । त्यात गुरूकृपा छत्र । सत्संग परिवार मित्र ॥ १५ ॥
महाराज गेले शेताला । संतोष झाला बंकटाला । समवेत मित्र परिवाराला । बोलवता झाला शेताला ॥ १६ ॥
शेतात मक्याची कणसे । जिकडे तिकडे दिसतसे । भरघोस पीक निघतसे । शेतात आनंदे डोलतसे ॥ १७ ॥
भली मोठी विहीर । तुडुंब भरलेली साचार । सभोवताली चिंचेची झाडे डेरेदार । उभी राहिली ऐटदार ॥ १८ ॥
चिंचेच्या झाडाखाली जमले । कणसे भाजणे नक्की केले । एक‌एक आगटी पेटवू लागले । दहा बारा पेटवते झाले ॥ १९ ॥
आगट्या पेटू लागल्या । निघू लागल्या ज्वाळा । धूरही निघू लागला । वर वर चौफेर पसरला ॥ २० ॥
चिंचेवर मधाचे पोळे । कुणी न त्यास पाहिले । आगट्या पेटवू लागले । तेच प्रसंगाचे कारण झाले ॥ २१ ॥
खालुन विस्तव पेटला । माशांना असह्य झाला । स्वतःचा जीव वाचवण्याला । सैरावैरा पळू लागल्या ॥ २२ ॥
त्यांनी घेरले सर्वांना । चावू लागल्या त्यांना । कुणासच तेथे बसवेना । क्षणभर उभे रहावेना ॥ २३ ॥
जो तो पळू लागला । आपला जीव जपू लागला । एरव्ही जीवास कंटाळलेला । येथे जीव वाचवू लागला ॥ २४ ॥
कुणी कुणासाठी न थांबला । जो तो धावू लागला । कणसे खाण्याला मुकला । विपरित प्रसंग आला ॥ २५ ॥
माशांनी सर्वांस केले त्रस्त । कुठचा जीव रमेल उपाधीत ? । सर्वांस वाटे रहावे उपाधी रहित । जो तो ऐशा प्रवृत्तीत ॥ २६ ॥
सर्वजण पळुन गेले । घाबरलेले पळाले । महाराज नव्हते घाबरलेले । जेणे ते तेथेच राहिले ॥ २७ ॥
महाराज बैसले निजानंदात । भितीचा लवलेश न चित्तात । परी माशा त्यांना सोडेनात । घेरले त्यांनी क्षणभरात ॥ २८ ॥
दुसर्‍यांस डसणे स्वभाव माशांचा । आसरा घेतला त्याचा । स्वबचाव करण्याचा । हाच हेतू माशांचा ॥ २९ ॥
दोष नाही माशांचा । दोष आहे दुष्कर्माचा । येतो प्रसंग जीव घेण्याचा । आसरा घेतला त्याचा ॥ ३० ॥
जीवनात कर्माला महत्व फार । जीवनात कर्मावर भार । सामान्य वेगळे आचरणार । असामान्य वेगळे आचरणार ॥ ३१ ॥
माशा पाठीवर बसल्या । सर्वांगावर बसल्या । माशांची पासोडीच अंगाला । जणू दिली पांघरायला ॥ ३२ ॥
क्षणाची न फुरसत त्यांना । डसू लागल्या समर्थांना । कोण अडवणार त्यांना ? । सर्वच घाबरले त्यांना ॥ ३३ ॥
महाराजांची सहनशीलता । कमालीची शांतता । कधी न अनुभवली तत्वता ??? । भिती न उरे माशांस आता ॥ ३४ ॥
महाराज वदती माशांस । तुम्ही डसावे आम्हांस । आम्हां न होणार त्रास । आम्ही जाणतो एका तत्वास ॥ ३५ ॥
जीव वेगळा नसे तुमचा आमचा । दुसर्‍यांस डसणे धर्म तुमचा । आचरा मनोधर्म तुमचा । रक्षण करणे धर्म आमचा ॥ ३६ ॥
सामान्य वागतो जशास तसे । असामान्य विरुद्ध वागतसे । स्वतःचे तत्व सोडत नसे । कुणाचे वाईट करत नसे ॥ ३७ ॥
समर्थ जगावेगळे वागतात । वृत्तीनेच ते समर्थ ठरतात । शत्रुचेही न अशुभ चिंततात । सर्वांचेच कल्याण करतात ॥ ३८ ॥
ते महत्व नाही देत स्वतःला । ते रमवतात दुसर्‍याला । महत्व नाही देत संकटाला । तेणे न दुःख वाट्याला ॥ ३९ ॥
शेवटी माशा कंटाळल्या । त्या तेथुन निघुन गेल्या । दूर दूर पळुन गेल्या । खोडी काढणे पार विसरल्या ॥ ४० ॥
बंकटास कळला वृत्तांत । भयभीत झाला चित्तात । गोंधळला क्षणार्धात । नाना तर्‍हेचे विचार मनात ॥ ४१ ॥
मित्र मंडळी त्याच्या बरोबर । गेली त्याच्या शेतावर । डॉक्टरही होते बरोबर । करण्या ईलाज औषधोपचार ॥ ४२ ॥
बंकट समोर भरभर गेला । समर्थांची माफी मागू लागला । हृदय बोलके करू लागला । नयनातील अश्रू सारू लागला ॥ ४३ ॥
महाराजांच्या पाठीत काटे रुतलेले । बंकटाने स्वतः पाहिले । मन दुःखी कष्टी झाले । उगाच समर्थ येथे आणले ॥ ४४ ॥
डॉक्टरांनी तपासले । काटे काढणे प्रयोजन केले । चिमटा पाठीस लावू लागले । एक एक काटा काढू लागले ॥ ४५ ॥
समर्थ बंकटास वदले । पाठीत काटे जे रुतलेले । आम्हांस त्यांनी न सतावले । नुसतेच जाऊन बसले ॥ ४६ ॥
योगबळावर काढू काटे । नको आम्हां तुमचे चिमटे । योगबळ एकवटले समर्थे । त्यासरशी निघाले रुतलेले काटे ॥ ४७ ॥
बंकटास गहिवरून आले । अंगावर रोमांच आले । समर्थांचे चरण धरले । नको ऐसे धाडस सांगितले ॥ ४८ ॥
खात्री देणे गुरूंना । जमत नाही सर्वांना । आपलेपणाचा भाव ज्याच्या मना । जागृत उत्कट भावना ॥ ४९ ॥
जो ऐसे आचरतो । तोच जीवनी धन्य ठरतो । वरवरपणा वरवर ठरतो । आतला उमाळा प्रभावी ठरतो ॥ ५० ॥
महाराजांचा स्वभाव लहरी । जे जे मनी ते ते आचरी । धावत असे वायुवेगापरी । न टिके कुणाच्या घरी ॥ ५१ ॥
असेच एकदा मनात आले । नजर सार्‍यांची चुकवते झाले । क्षणभरात शेगाव सोडले । वायुवेगे पळाले ॥ ५२ ॥
ते गेले अकोटास । भेटण्या नरसिंगास । सारखाच भेटे सारख्यास । ह्याचे महत्व न दुसर्‍यास ॥ ५३ ॥
नरसींग कोतशा अल्लींचा भक्त । तो होता विठ्ठलाचा भक्त । जो रमला विठ्ठलात । तो वरवर दिसे प्रपंचात ॥ ५४ ॥
तो खरा भक्तीतच रमणार । भक्तीवीण उदास वाटणार । जीव न उदासीनतेत रमणार । तो प्रसन्नताच मागणार ॥ ५५ ॥
भक्त कधी न उदासीनतेत । भक्त सदैव प्रसन्नतेत । मंगलमय वातावरणात । पवित्र वातावरणात ॥ ५६ ॥
प्रपंची भक्तीस घाबरतात । प्रपंचात गुरफटतात । विचारांचे धागे विणतात । त्याच जाळ्यात अडकतात ॥ ५७ ॥
समज गैरसमज ठरतो । दुसर्‍यांचे अनुकरण करतो । चारचौघांसारखे वागतो । स्वयेच स्वतःचा घात करतो ॥ ५८ ॥
दुसर्‍यांचे अनुकरण करावे उक्ती । हीच शिकवण खोटी । जी उपाधी करते मोठी । तेणे संकटे वरच्यावरती ॥ ५९ ॥
प्रत्येकाचा जीव वेगळा । प्रत्येकाचा पिंड वेगळा । पूर्वसंचित साठा वेगळा । हेच येते अनुभवाला ॥ ६० ॥
पूर्वसंचित जसे असेल । तसे फळ भोगावे लागेल । प्रत्येकाचे वेगळे असेल । त्यात नव्या कर्माची भर पडेल ॥ ६१ ॥
हे सारे सामान्य जीवन । नरसिंगाचे वेगळे जीवन । सर्व साधले प्रपंची राहुन । तो न गेला प्रपंच सोडुन ॥ ६२ ॥
कर्म भक्ती योग मार्गावर । गप्पा झाल्या रात्रभर । नव्हता परनिंदेवर भर । आत्मस्तुती अहंभावावर ॥ ६३ ॥
परनिंदा न कुणा तारी । परस्त्रीच्या जाऊ नये आहारी । ऐशी कर्मे ठरती विषारी । कधी न उपयुक्त संसारी ॥ ६४ ॥
विष ठरते नाशास कारण । नको नको दुराचरण । तेणे काळास आमंत्रण । तेणे भयभीत जीवन ॥ ६५ ॥
समर्थ नरसिंगास वदतात । तू बरा प्रपंचात । आम्ही रमलो योगमार्गात । कैसे रममाण प्रपंचात ? ॥ ६६ ॥
मी न राहणार स्थिर । तू प्रपंचात आहे स्थिर । तू कर्ममार्गात स्थिर । मी योगमार्गात स्थिर ॥ ६७ ॥
जरी आपले भिन्न क्षेत्र । एकाच देहाची दोन नेत्र । एकाचे कर्मक्षेत्र । दुसर्‍याचे योग क्षेत्र ॥ ६८ ॥
तू मी वेगळा कायेने । परी आहोत एकाच विचाराने । आपले कार्य विधात्याने । नेमून दिले पूर्वसंचिताने ॥ ६९ ॥
दोघांच्या भेटीचे वृत्त कळले । गावकरी आनंदीत झाले । आशिर्वादास उत्सुकलेले । दोघांस भेटणे नक्की केले ॥ ७० ॥
समर्थे अंतर्ज्ञानाने जाणले । ते नरसिंगास वदले । नको उपाधी जाळे । भेटण्याचे टाळले ॥ ७१ ॥
महाराज पहाटेसच निघाले । अकोट गावातुन अदृष्य झाले । गावकरी भेटण्यास आले । परी सारे हताश झाले ॥ ७२ ॥
दोन संतांची भेट । नव्हती प्रारब्धात । प्रारब्ध ठरले श्रेष्ठ । प्रयत्न ठरले व्यर्थ ॥ ७३ ॥
गावकरी निरुत्साही झाले । हातातले फुलहार तसेच राहिले । जे नव्हते भाग्यात लिहिलेले । तेच येथे अनुभवास आले ॥ ७४ ॥
वस्तुंचे सुद्धा भाग्य असावे लागते । हेच म्हणणे उचित ठरते । सगळीच फुले नाही उमलत । जी भगवंत चरणी लोळत ॥ ७५ ॥
कर्माच्या गती असतात गहन । हे न जाणणार मूढ जन । कर्मासच मिळते प्राधान्य । कर्म फळावाचून न सुटणार जन ॥ ७६ ॥
समर्थांच्या ऐशा चमत्कृती । काय न वर्णणार सरस्वती ? । संत नामाची महती । नाम सर्वांच्या मुखावरती ॥ ७७ ॥
नाममहिमा सरस्वती वर्णणार । जी आनंदित होणार । जी आनंद अनुभवणार । तीच दुसर्‍यांस देणार ॥ ७८ ॥
भाग्य नुसते नाही येत उदयास । प्रयत्न करावे लागती त्यास । नाही प्रयत्नांवाचून यश । हेच येते अनुभवास ॥ ७९ ॥
दुसर्‍याचे सुख हिरावण्यात । काही नाही मिळत । ते कधी नाही टिकत । ते सर्व व्यर्थ ठरत ॥ ८० ॥
ऐसे आपण मिळवावे । जेणे सच्चिदानंदे तोषावे । हरामाचे कधी खाऊ नये । त्याने देह पोसू नये ॥ ८१ ॥
कोण वागतो ऐसे ? । ज्याचे चित्त शुद्ध असे । त्याच्या मनी शुद्ध विचार वसे । तेणेच सुकर्मे होतसे ॥ ८२ ॥
नुसती उपासना न कामास । महत्व असते मनोवृत्तीस । महत्व असते शुद्ध चित्तास । तेणेच वाव सत्कर्मास ॥ ८३ ॥
शुद्ध हेतू लागतो चित्तात । तेणे तैसे वर्तनात । शुद्ध हेतू ज्याच्या मनात । कलह कपटे नसतात ॥ ८४ ॥
जेथे असते शुद्धता । कैसी टिकणार मलीनता ? । जेथे नसते मलीनता । तेथे असते प्रसन्नता ॥ ८५ ॥
जेथे मलीनतेचे निर्मूलन । तेथे मंगलमय वातावरण । तेथे प्रसन्न वातावरण । जेणे सच्चिदानंद आगमन ॥ ८६ ॥
जीवन ऐसे जगावे । दुसर्‍यास उपदेशात्मक असावे । कोरडे उपदेश नसावे । कृतीनेच सार्थक करावे ॥ ८७ ॥
ऐशी आपली वृत्ती असावी । जनांच्या हृदयी चिरकाल टिकावी । परोपकारी वृत्ती असावी । हीच ईश्वर सेवा मानावी ॥ ८८ ॥
नुसते शिकुन नाही होत । यावे लागते आचरणात । यावे लागते सहजगतेत । सहजगता असावी मुळात ॥ ८९ ॥
जे जन सहजभाव जाणतात । तेच खर्‍या अर्थाने जगतात । पुण्याई ज्यांच्या पदरात । तेणे सहजगता जीवनात ॥ ९० ॥
महाराज सहज वावरत । मनात येई तेथे हिंडत । एकदा शिष्यांसह दर्यापुरात । शिवरगाव सान्निध्यात ॥ ९१ ॥
व्रजभूषण तेथे पंडित । कीर्ती विद्वत्ता पदरात । चार भाषा अवगत । होता वेद पारंगत ॥ ९२ ॥ 
येथे समर्थ आले असता । व्रजभूषण झाला त्यांना पहाता । म्हणे योगी पुरुष हा तत्वता । चरणी ठेवावा माथा ॥ ९३ ॥
जो रोज सूर्यास अर्घ्ये देणारा । नित्यनेम करणारा । तपश्वर्येचे महत्व जाणणारा । भक्ती भावे जगणारा ॥ ९४ ॥
त्याने समर्थांस ओळखले । म्हणे सूर्यनारायणच प्रकटले । आज भाग्य उदयास आले । महाराजांस अर्घ्य दिले ॥ ९५ ॥
महत्व आहे हृदयातल्या भावाला । तैशी कृती आचरण्याला । जैसा भाव हृदयातला । तैसा वाव कृतीला ॥ ९६ ॥
अडवणार तिला कोण ? । समर्थांना मानले सूर्यनारायण । खर्‍या अर्थी जाणले तत्वज्ञान । तैसेच केले आचरण ॥ ९७ ॥
व्रजभूषणे घातले द्वादश नमस्कार । हृदयातला भाव झाला साकार । जो भाव असतो निराकार । तोच कृतीने होतो साकार ॥ ९८ ॥
मूळचाच तो वेदपारायण संपन्न । त्यात लाभले गजानन । लाभता कृपाशिष चरण । धन्य धन्य झाला व्रजभूषण ॥ ९९ ॥
माता जशी लेकराला । जवळ घेते त्याला । तैसेच व्रजभूषणाला । समर्थांनी धरले त्याला ॥ १०० ॥
आशिर्वाद दिला थोर । बाळा तुझा जयजयकार । जाशील कीर्ती शिखरावर । होशील अजरामर ॥ १०१ ॥
कर्मयोग सोडू नको । विधी निरर्थक मानू नको । लिप्त त्यात होऊ नको । संकटास घाबरू नको ॥ १०२ ॥ 
जावे आता तुझ्या सदनी । माझे बोल ठेव कानी । कर्ममार्ग आचरुनी । धन्य होशील जीवनी ॥ १०३ ॥ 
श्रीफळ दिले त्यास । आनंद झाला व्रजभूषणास । तपश्चर्या आली फळास । भाग्य आले उदयास ॥ १०४ ॥
जैसे व्रजभूषणाचे भाग्य उदयास । तैसेच येवो आपणास । गणामहाराज प्रार्थितो देवदेवतांस । कृपाशिष लाभो सर्वांस ॥ १०५ ॥
ज्यांच्या कृपे केले वर्णन । त्या सर्वांस शतशः वंदन । वाटे संतुष्ट करावे भक्तगण । हीच प्रार्थना मनोमन ॥ १०६ ॥


॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य षष्ठोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
